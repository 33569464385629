import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../slices/userSlice';
import HeaderBar from '../Layout/HeaderBar';
import { Box, TextField, Button, Container, Snackbar, Alert, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useScreenSize } from '../ScreenSizeContext';
import API from '../API/API';
import JustLogo from '../../img/K-SPelastuslaitos-logo.svg';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize(); // Get screen size info

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setEmailError(false);
    setPasswordError(false);
    try {
      const response = await API.post('/login', { email, password });
      if (response.data) {
        const { accessToken } = response.data;

        // Store tokens in localStorage
        localStorage.setItem('accessToken', accessToken);

        // Dispatch user information to the Redux store
        const { 
          id, 
          firstname, 
          lastname, 
          area, 
          role, 
          phonenumber, 
          ergometry_expirary_date, 
          fitness_test_expirary_date, 
          oulunmalli_expirary_date,
          mobility_test_expirary_date,
          goal,
          fire_station
        } = response.data;
        dispatch(setUser({ 
          id, 
          firstname, 
          lastname, 
          email, 
          role, 
          area, 
          phonenumber, 
          ergometry_expirary_date, 
          fitness_test_expirary_date,
          oulunmalli_expirary_date,
          mobility_test_expirary_date,
          goal,
          fire_station
        }));

        // Navigate to home page
        navigate('/');
      } else {
        setMessage(`Jokin odottamaton virhe tapahtui.\n
          Virhe on kirjattu ja sitä selvitetään.\n
          Ole hyvä ja yritä myöhemmin uudestaan.\n\n
          Pahoittelut asian johdosta.`);
        setOpenSnackbar(true);
      }
    } catch (error: any) {
      if (error.response) {
        const { message } = error.response.data;
        setMessage(message);
        if (message === 'Antamasi sähköpostiosoite on virheellinen') {
          setEmailError(true);
        } else if (message === 'Antamasi salasana on virheellinen') {
          setPasswordError(true);
        } else {
          setOpenSnackbar(true);
        }
      } else {
        setMessage(`Jokin odottamaton virhe tapahtui.\n
          Virhe on kirjattu ja sitä selvitetään.\n
          Ole hyvä ja yritä myöhemmin uudestaan.\n\n
          Pahoittelut asian johdosta.`);
        setOpenSnackbar(true);
      }
      setTimeout(() => {
        setEmailError(false);
        setPasswordError(false);
        setMessage('');
      }, 5000);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box>
      <HeaderBar isMobile={isMobile} />
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img 
            src={JustLogo} 
            alt="Loading Logo" 
            style={{ 
              height: isMobile ? '100px' : '200px', 
              transition: 'height 0.3s ease' // Optional: smooth transition
            }}
          />
          <Box component="form" onSubmit={handleLogin} sx={{ width: '80%', mt: 2 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Sähköposti"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError}
              helperText={emailError ? message : ''}
              sx={{ 
                borderRadius: 1, 
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: emailError ? 'red' : 'rgba(0, 0, 0, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: emailError ? 'red' : 'rgba(0, 0, 0, 0.23)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: emailError ? 'red' : '#3f51b5',
                  },
                },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Salasana"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
              helperText={passwordError ? message : ''}
              sx={{ 
                borderRadius: 1, 
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: passwordError ? 'red' : 'rgba(0, 0, 0, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: passwordError ? 'red' : 'rgba(0, 0, 0, 0.23)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: passwordError ? 'red' : '#3f51b5',
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Kirjaudu sisään
            </Button>
          </Box>
        </Box>
      </Container>
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={5000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            top: '30%', // Adjust this value to position it closer to the center
            left: '50%',
            transform: 'translate(-50%, -30%)', // Adjusts the position to center it correctly
            padding: '50px'
          }
        }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%', whiteSpace: 'pre-line' }}>
          {message}
        </Alert>
      </Snackbar>
      <Button
        variant="text"
        color="primary"
        onClick={() => navigate('/forgot-password')}
        sx={{ mt: 2, maxWidth: '250px', mx: 'auto', display: 'block' }}
      >
        Unohditko salasanasi?
      </Button>
    </Box>
  );
};

export default Login;
