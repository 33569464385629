import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import BookingCalendar from './Calendar/BookingCalendar';
import { Box, Typography, CardContent, Tabs, Tab, List, Paper, Grid, Divider, ListItemButton, ListItemText, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import API from '../API/API';
import { setBookings, setAvailableDates } from '../../slices/bookingSlice';
import BookingItem from './Reservations/BookingItem';
import { useScreenSize } from '../ScreenSizeContext';
import ReservationDetailsModal from '../ReservationDetailsModal';
import { getStatus } from '../utils/statusConverterFunctions';
import NotificationBoard from './NotificationBoard/NotificationBoard';

const UserView: React.FC = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.user);
  const bookings = useSelector((state: RootState) => state.bookings.bookings || []);
  const [userBookings, setUserBookings] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const { isDesktop, isMobile } = useScreenSize(); // Add isMobile
  const [selectedBooking, setSelectedBooking] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchBookingsAndAvailableDates = async () => {
      try {
        const response = await API.get('/varaukset');
        const availableDatesResponse = await API.get('/available');

        const fetchedBookings = response.data.bookings.map((booking: any) => ({
          id: booking.id,
          start: booking.start,
          end: booking.end,
          title: booking.title,
          area: booking.area,
          userId: booking.userId,
          userRole: booking.userRole,
          firstname: booking.firstname,
          lastname: booking.lastname,
          phonenumber: booking.phonenumber,
          fire_station: booking.fire_station,
          tester: booking.tester,
          approved: booking.approved,
        }));

        const fetchedAvailableDates = availableDatesResponse.data.map((available: any) => ({
          available_date_id: available.id,
          tester_firstname: available.firstname,
          tester_lastname: available.lastname,
          available_date: available.date,
          available_startTime: available.start_time,
          available_endTime: available.end_time,
          is_available: available.is_available,
          fire_station: available.fire_station,
        }));

        dispatch(setBookings(fetchedBookings));
        dispatch(setAvailableDates(fetchedAvailableDates));
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };

    fetchBookingsAndAvailableDates();
  }, [dispatch]);

  useEffect(() => {
    const populateUserReservationsState = () => {
      try {
        const userSpecificBookings = bookings.filter(booking => booking.userId === userInfo.id);
        setUserBookings(userSpecificBookings);
      } catch (error) {
        console.error('Failed to filter user bookings', error);
      }
    };

    populateUserReservationsState();
  }, [bookings, userInfo]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleDropdownChange = (event: any) => {
    setActiveTab(Number(event.target.value));
  };

  const renderTabs = () => (
    <Tabs value={activeTab} onChange={handleTabChange} aria-label="User actions tabs" style={{ marginBottom: '16px' }}>
      <Tab label="Kalenteri" />
      <Tab label="Varaukset" />
      <Tab label="Ilmoitustaulu" />
    </Tabs>
  );

  const renderDropdown = () => (
    <FormControl fullWidth sx={{ my: 3}}>
      <InputLabel id="tab-dropdown-label">Valitse näkymä</InputLabel>
      <Select
        labelId="tab-dropdown-label"
        id="tab-dropdown"
        value={activeTab}
        onChange={handleDropdownChange}
      >
        <MenuItem value={0}>Kalenteri</MenuItem>
        <MenuItem value={1}>Varaukset</MenuItem>
        <MenuItem value={2}>Ilmoitustaulu</MenuItem>
      </Select>
    </FormControl>
  );

  const renderCalendarContent = (content: JSX.Element) => (
    isDesktop ? (
      <Paper elevation={3} style={{ padding: '16px' }}>
        <CardContent>{content}</CardContent>
      </Paper>
    ) : (
      <Box>{content}</Box>
    )
  );

  const renderPaperContent = (content: JSX.Element) => (
    <Paper elevation={3} style={{ padding: '16px' }}>
      <CardContent>{content}</CardContent>
    </Paper>
  );

  const handleBookingClick = (booking: any) => {
    setSelectedBooking(booking);
    setIsModalOpen(true);
  };

  const RegularUserTabs = () => (
    <>
      {isMobile ? renderDropdown() : renderTabs()}
      {activeTab === 0 && renderCalendarContent(<BookingCalendar area={userInfo.area} role={userInfo.role} />)}
      {activeTab === 1 && renderPaperContent(
        <>
          <Typography variant="h6" gutterBottom pb={2}>
            Omat varaukset
          </Typography>
          <Grid container spacing={2} sx={{fontWeight: 'bold', marginBottom: '8px' }} justifyContent="space-between" alignItems="center">
          <Grid item xs={1.5} container justifyContent="center">Testi</Grid>
          <Grid item xs={1.5} container justifyContent="center">Varaaja</Grid>
          <Grid item xs={1.5} container justifyContent="center">Asema</Grid>
          <Grid item xs={1.5} container justifyContent="center">Päivämärää</Grid>
          <Grid item xs={1.5} container justifyContent="center">Aloitusaika</Grid>
          <Grid item xs={1.5} container justifyContent="center">Lopetusaika</Grid>
          <Grid item xs={1.5} container justifyContent="center">Status</Grid>
        </Grid>
          {userBookings.length > 0 ? (
            <List>
              {userBookings.map((booking, index) => (
                <React.Fragment key={booking.id}>
                  {isDesktop ? (
                    <BookingItem booking={booking} />
                  ) : (
                    <ListItemButton onClick={() => handleBookingClick(booking)}>
                      <ListItemText
                        primary={booking.title}
                        secondary={`Status: ${getStatus(booking.approved)}`}
                      />
                    </ListItemButton>
                  )}
                  {index < userBookings.length - 1 && <Divider style={{ margin: '10px 0' }} />}
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography variant="body1" align="center" sx={{ mt: 3 }}>
              Ei varauksia
            </Typography>
          )}
        </>
      )}
      {activeTab === 2 && <NotificationBoard />}
    </>
  );

  const TesterTabs = () => (
    <>
      {isMobile ? renderDropdown() : renderTabs()}
      {activeTab === 0 && renderCalendarContent(<BookingCalendar area={userInfo.area} role={userInfo.role} />)}
      {activeTab === 1 && renderPaperContent(
        <>
          <Typography variant="h6" gutterBottom pb={2}>
            Sinulle tehdyt varaukset
          </Typography>
          <Grid container spacing={2} sx={{fontWeight: 'bold', marginBottom: '8px' }} justifyContent="space-between" alignItems="center">
          <Grid item xs={1.5} container justifyContent="center">Testi</Grid>
          <Grid item xs={1.5} container justifyContent="center">Varaaja</Grid>
          <Grid item xs={1.5} container justifyContent="center">Asema</Grid>
          <Grid item xs={1.5} container justifyContent="center">Päivämärää</Grid>
          <Grid item xs={1.5} container justifyContent="center">Aloitusaika</Grid>
          <Grid item xs={1.5} container justifyContent="center">Lopetusaika</Grid>
          <Grid item xs={1.5} container justifyContent="center">Status</Grid>
        </Grid>
          {bookings.filter(booking => booking.tester === `${userInfo.firstname} ${userInfo.lastname}`).length > 0 ? (
            <List>
              {bookings
                .filter(booking => booking.tester === `${userInfo.firstname} ${userInfo.lastname}`)
                .map((booking, index) => (
                  <React.Fragment key={booking.id}>
                    {isDesktop ? (
                      <BookingItem booking={booking} />
                    ) : (
                      <ListItemButton onClick={() => handleBookingClick(booking)}>
                        <ListItemText
                          primary={booking.title}
                          secondary={`Varaaja: ${booking.firstname} ${booking.lastname}`}
                        />
                      </ListItemButton>
                    )}
                    {index < bookings.length - 1 && <Divider style={{ margin: '10px 0' }} />}
                  </React.Fragment>
                ))}
            </List>
          ) : (
            <Typography variant="body1" align="center" sx={{ mt: 3 }}>
              Ei varauksia
            </Typography>
          )}
        </>
      )}
      {activeTab === 2 && <NotificationBoard />}
    </>
  );

  return (
    <Box className="userview-container" p={3}>
      {userInfo.role === 'Testaaja' ? <TesterTabs /> : <RegularUserTabs />}
      {selectedBooking && (
        <ReservationDetailsModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          reservation={selectedBooking}
        />
      )}
    </Box>
  );
};

export default UserView;
