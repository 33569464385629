import React, { useState } from 'react';
import { TextField, Button, Snackbar, Alert, Container, Box } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import API from '../API/API';

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Salasanat eivät täsmää.');
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await API.post('/reset-password', { token, password });
      if (response.data) {
        setMessage('Salasana on vaihdettu onnistuneesti.');
        setOpenSnackbar(true);

        // Redirect to login after a few seconds
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      } else {
        setMessage('Jokin virhe tapahtui, yritä myöhemmin uudelleen.');
        setOpenSnackbar(true);
      }
    } catch (error) {
      setMessage('Jokin virhe tapahtui, yritä myöhemmin uudelleen.');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Box component="form" onSubmit={handlePasswordReset} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="password"
          label="Uusi salasana"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="password"
          label="Vahvista uusi salasana"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
          Päivitä salasana
        </Button>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ResetPassword;
