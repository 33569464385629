import React, { useState } from 'react';
import { TextField, Button, Snackbar, Alert, Container, Box } from '@mui/material';
import API from '../API/API';
import { useNavigate } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await API.post('/request-password-reset', { email });
      if (response.data) {
        setMessage('Salasanan vaihtoon tarvittava linkki on lähetetty sähköpostiisi');
        setOpenSnackbar(true);
        
        // Redirect to /login after 5 seconds
        setTimeout(() => {
          navigate('/login');
        }, 5000); // Adjust time (in milliseconds) as needed
      } else {
        setMessage('Jokin virhe tapahtui, yritä myöhemmin uudelleen.');
        setOpenSnackbar(true);
      }
    } catch (error) {
      setMessage('Jokin virhe tapahtui, yritä myöhemmin uudelleen.');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Box component="form" onSubmit={handleForgotPassword} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Sähköposti"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
          Resetoi salasana
        </Button>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ForgotPassword;
